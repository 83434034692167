<template>
 <div class="mask-parent">
    <div class="mask mask--relative" v-if="isLoading">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <form
      id="payment-form"
      class="stripe"
      @submit.prevent="pay"
    >
      <div class="stripe__row">
        <label for="card-name" class="stripe__row__label">
          Titulaire de la carte
        </label>

        <div id="card-name"  class="stripe__row__input">
          <input type="text" v-model="name" required />
        </div>
      </div>

      <div id="card-errors" role="alert"></div>
      <button class="btn btn-valid">Payer</button>
    </form>
  </div>
</template>

<script>
/* global Stripe */

import { mapGetters, mapActions } from 'vuex';
import { errorActions } from '../../mixins';

export default {
  mixins: [errorActions],

  props: {
    stripeKey: {
      type: String,
      default: process.env.VUE_APP_STRIPE_TEST,
    },

    intent: {
      type: Boolean,
      default: false,
    },

    amount: {
      type: Number,
      default: 0,
    },

    activeCart: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      stripe: null,
      card: null,
      name: null,
      isLoading: false,
    };
  },

  mounted() {
    this.name = this.env === 'dev' ? 'Dave' : null;
  },

  computed: {
    ...mapGetters({
      env: 'app/env',
      url: 'app/url',
      auth: 'app/auth',
    }),
  },


  methods: {
    ...mapActions({
      setSource: 'app/setSource',
    }),

    pay() {
      this.$emit('load');
      this.isLoading = true;
      this.stripe = Stripe(this.stripeKey);

      this.stripe.createSource({
        type: 'bancontact',
        amount: this.amount,
        currency: 'eur',
        metadata: {
          cart: this.activeCart,
          email: this.auth.email,
          id: this.auth.id,
        },
        statement_descriptor: 'Commande Widddi',
        owner: {
          name: this.name,
        },
        redirect: {
          return_url: `${this.url}/panier?bc=true&activeCart=${this.activeCart}&uid=${this.auth.uid}`,
        },
      })
        .then((result) => {
          if (result.error !== undefined) {
            this.handleErrors({ err: result.error });
            window.location = `${this.url}/oups?activeCart=${this.activeCart}`;
          }

          if (result.source !== undefined) {
            this.setSource(result.source);
            this.$nextTick(() => {
              window.location = result.source.redirect.url;
            });
          }
        })
        .catch((err) => {
          this.handleErrors({ err });
        });
    },
  },
};
</script>
