<template>
  <transition name="modal">
    <div class="modal-mask" @click="clickOutside">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer row">
            <slot name="footer">
              <div class="col-sm-6 col-xs-12 tleft">
                <button class="btn btn-border" @click="$emit('close')">
                  <i class="fa fa-chevron-left"></i>  Continuer mes achats
                </button>
              </div>
              <div class="col-sm-6 col-xs-12 tright">
                <button class="btn btn-valid" @click="$emit('route')">
                  Voir mon panier et payer <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    clickOutside(e) {
      if (
        e.target.className.indexOf('modal-mask') > -1 ||
        e.target.className.indexOf('modal-wrapper') > -1
      ) {
        this.$emit('close', e);
      }
    },
  },
};
</script>
