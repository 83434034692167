<template>
  <div :class="['alert-baby', { 'alert-baby--menu': menu }]">
    <p>
      <strong><u>IMPORTANT</u></strong> : Nous profiterons de nos premières vacances en famille en septembre.
      <br />Les commandes seront donc fermées entre le <u>1er et le 26 septembre</u>.
      <br />Toutes les commandes passées en août seront expédiées avant notre départ.
      <br />Au plaisir de vous retrouver aussi nombreux.ses à notre retour.

      <br v-if="nbDaysLeft > 0" />

      <strong v-if="!onHolidays" class="alert-baby__obvious">
        <span v-if="!lessThan1Day && nbDaysLeft > 0">
          Plus que {{ nbDaysLeft }} jour<span v-if="nbDaysLeft > 1">s</span>
          pour passer commande.
        </span>

        <span v-if="lessThan1Day && nbDaysLeft > 0">
          Plus que quelques heures pour passer commande.
        </span>
      </strong>

      <strong v-else-if="onHolidays" class="alert-baby__obvious">
        <span v-if="!lessThan1DayBeforeWork && nbDaysLeft > 0">
          Plus que {{ nbDaysLeft }} jour<span v-if="nbDaysLeft > 1">s</span>
          avant la reprise des commandes.
        </span>

        <span v-if="lessThan1DayBeforeWork && nbDaysLeft > 0">
          Plus que quelques heures avant la reprise des commandes.
        </span>
      </strong>
    </p>
  </div>
</template>

<script>
import alertBaby from '../../mixins/alertBaby';

export default {
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [alertBaby],
};
</script>
