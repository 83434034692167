<template>
  <div class="qty">
    <button @click.prevent="decrement">-</button>
    <input
      type="number"
      class="classic-input"
      :value="quantity"
      @input="newQuantity($event.target.value)"
    >
    <button @click.prevent="increment">+</button>
  </div>
</template>

<script>
export default {
  name: 'quantity',
  props: {
    index: {
      type: Number,
      default: null,
    },
    quantity: { type: Number },
  },
  methods: {
    decrement() {
      if (this.quantity > 1) {
        const qty = this.quantity - 1;
        const { index } = this;
        this.$emit('quantity', { type: 'removeQty', index, qty });
      }
    },
    increment() {
      const qty = this.quantity + 1;
      const { index } = this;
      this.$emit('quantity', { type: 'addQty', index, qty });
    },
    newQuantity(quantity, index = this.index) {
      let qty = parseInt(quantity, 10);

      if (!Number.isNaN(qty)) {
        this.$emit('quantity', { type: 'updateQty', index, qty });
      } else {
        qty = this.quantity;
        this.$emit('quantity', { type: 'updateQty', index, qty });
      }
    },
  },
};
</script>
