<template>
  <div
    :class="{
      vdpBig: big,
      vdpDisabled: disabled,
    }"
    @click="$emit('focus')"
  >
    <date-pick
      :value="value"
      :format="format"
      :nextMonthCaption="nextMonthCaption"
      :prevMonthCaption="prevMonthCaption"
      :setTimeCaption="setTimeCaption"
      :weekdays="weekdays"
      :months="months"
      :inputAttributes="{ readonly: true }"
      :isDateDisabled="isDateDisabled"
      @input="changeDate"
    ></date-pick>
  </div>
</template>

<script>
import DatePick from 'vue-date-pick';

export default {
  components: { DatePick },

  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    big: { type: Boolean, default: false },
    disablePreviousDates: { type: Boolean, default: false },
    disableFutureDates: { type: Boolean, default: false },
  },

  data() {
    return {
      format: 'DD/MM/YYYY',
      nextMonthCaption: 'Mois suivant',
      prevMonthCaption: 'Mois précédent',
      setTimeCaption: 'Heure :',
      weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
      months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
    };
  },

  methods: {
    changeDate(e) {
      this.$emit('input', e);
    },

    isDateDisabled(date) {
      const currentDate = new Date();

      return (
        (this.disablePreviousDates && date < currentDate) ||
        (this.disableFutureDates && date > currentDate)
      );
    },
  },
};
</script>
