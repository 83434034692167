<template>
  <div class="mask-parent">
    <div class="mask mask--relative" v-if="isLoading">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <form
      :key="key"
      id="payment-form"
      class="stripe"
      @submit.prevent="formSubmit"
    >
      <div id="card-element"></div>

      <div id="card-errors" role="alert"></div>
      <button class="btn btn-valid">Payer</button>
    </form>
  </div>
</template>

<script>
/* global Stripe */

import { mapGetters } from 'vuex';
import qs from 'qs';
import axios from 'axios';
import { errorActions } from '../../mixins';

export default {
  mixins: [errorActions],

  props: {
    stripeKey: {
      type: String,
      default: process.env.VUE_APP_STRIPE_TEST,
    },

    intent: {
      type: Boolean,
      default: false,
    },

    amount: {
      type: Number,
      default: 0,
    },

    idIntent: {
      type: String,
      default: '',
    },

    activeCart: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      name: null,
      key: 0,
      clientSecret: undefined,
      isLoading: false,
    };
  },

  mounted() {
    if (this.intent) {
      this.intentPayment();
    }
  },

  computed: {
    ...mapGetters({
      url: 'app/url',
      baseurl: 'app/baseurl',
      auth: 'app/auth',
    }),
  },

  watch: {
    intent() {
      if (this.intent) {
        this.intentPayment();
      }
    },
  },

  methods: {
    intentPayment() {
      this.isLoading = true;

      const datas = {
        amount: this.amount,
        currency: 'eur',
        metadata: {
          cart: this.activeCart,
          email: this.auth.email,
          id: this.auth.uid,
        },
        statement_descriptor: 'Commande Widddi',
      };

      axios
        .post(
          `${this.baseurl}/orders/payment-intent`,
          qs.stringify(datas),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
        .then(response => {
          this.clientSecret = response.data.client_secret;

          this.stripe = Stripe(this.stripeKey);
          this.elements = this.stripe.elements({
            clientSecret: this.clientSecret,
          });
          this.card = this.elements.create('payment');

          this.card.mount('#card-element');
          this.isLoading = false;

          this.$emit('intent', response.data.id);
        })
        .catch(err => {
          this.handleErrors({ err });
        });
    },

    formSubmit() {
      this.$emit('load');
      this.isLoading = true;

      this.stripe
        .confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: `${this.url}${
              this.$router.resolve({ name: 'Checkout' }).href
            }?activeCart=${this.activeCart}`,
          },
        })
        .then(result => {
          if (result.error) {
            return this.$emit('error', {
              message: result.error.message,
              activeCart: this.activeCart,
            });
          }

          if (result.paymentIntent.status === 'succeeded') {
            return this.$emit('success');
          }
        })
        .catch(err => {
          this.handleErrors({ err });
        });
    },
  },
};
</script>

<style>
#card-element {
  height: auto !important;
  border: none;
  margin-bottom: 20px;
  padding: 0;
}
</style>
